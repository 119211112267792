.projects__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.projects__item {
    background: var(--color-bg-variant);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.projects__item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}

.projects__item-image {
    border-radius: 1.5rem;
    overflow: hidden;
    object-fit: fill;
}

.projects__item h3 {
    margin: 1.2rem 0 0.5rem;
}

.projects__item ul {
    display: flex;
}

.projects__item li {
    color: var(--color-white);
    padding: 0.5rem;
}

.projects__item p {
    margin-bottom: 1rem;
}

.projects__item-btn {
    display: flex;
    gap: 1rem;
}

.projects__item-btn a {
    color: var(--color-primary);
    padding: 0.8rem;
    border-radius: 0.7rem;
    display: flex;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}

.projects__item-btn a:hover {
    background-color: var(--color-white);
    color: var(--color-bg);
    border-color: var(--color-bg);
}

@media screen and (max-width: 1024px) {
    .projects__container {
        grid-template-columns: 1fr 1fr;;
        gap: 1.2rem;
    }
}

@media screen and (max-width: 600px) {
    .projects__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}