header {
    height: 100vh;
    overflow: hidden;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.header__inner {
    position: relative;
    top: 50%;
    transform: translateY(-70%);
}

.header__subtitle {
    font-size: 1.5rem;
}

.header__above-title {
    font-size: 1.2rem;
}

.header__buttons {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header__socials::after {
    content: '';
    width: 1px;
    height: 5rem;
    background: var(--color-primary);
}

.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

@media screen and (max-width: 1024px) {
    header {
        height: 68vh;
    }
}

@media screen and (max-width: 600px) {
    header {
        height: 100vh;
    }

    .header__socials,
    .scroll__down {
        display: none;
    }
}