.experience__container {
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.experience__container > div {
    background: var(--color-bg-variant);
    padding: 2.4rem 3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
    margin-bottom: 2rem;
}

.experience__container > div:hover {
    background:transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.experience__container img {
    width: 50%;
    margin-bottom: 2rem;
}

.experience__company {
    color: var(--color-white);
}

.experience__title {
    color: var(--color-primary);
    padding-bottom: 0.5rem;
}

.experience__info {
    display: flex;
    color: var(--color-white);
    padding-bottom: 1rem;
}

.experience__info-item {
    display: flex;
}

.experience__info h4 {
    margin-right: 1rem;
}

.experience__icon {
    margin-top: 5px;
    margin-right: 0.5rem;
}

.experience__details {
    display: flex;
    gap: 1rem;
}

.experience__details h4 {
    padding-bottom: 0.2rem;
}

.experience__details-icon {
    margin-top: 6px;
    color: var(--color-white);
}

@media screen and (max-width: 1300px) {
    .experience__info {
        flex-direction: column;
    }
}

@media screen and (max-width: 1024px) {
    .experience__container {
        grid-template-columns: 1fr;
    }

    .experience__container > div {
        width: 80%;
        padding: 2rem;
        margin: 2rem auto;
    }    

    .experience__content {
        padding: 1rem;
    }

    .experience__container > img {
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .experience__container {
        gap: 1rem;
    }

    .experience__container > div {
        width: 100%;
        padding: 1.5rem 1.6rem;
    }
}