.about__container {
    display: grid;
    column-count: 1;
    gap: 3rem;
    align-items: center;
}

.about__me {
    display: grid;
    grid-template-columns: 30% 60%;
    gap: 10%;
    align-items: center;
    border-radius: 2rem;
}

.about__me-image {
    border-radius: 2rem;
    overflow: hidden;
}

.about__paragraph {
    margin: 2rem;
    color: var(--color-light);
    font-size: 1rem;
}

.about__skills {
    background: var(--color-bg-variant);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.about__skills:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}

.about__skills h2 {
    color: var(--color-primary);
    text-align: center;
}

.about__skills ul {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.about__skills li {
    margin: 0.5rem;
    text-align: center;
    border-radius: 2rem;
    border: 1px solid var(--color-primary);
}

.about__skills li:hover {
    background-color: var(--color-white);
    color: var(--color-bg);
    border-color: var(--color-bg);
}

@media screen and (max-width: 1700px) {
    .about__skills ul {
        grid-template-columns: repeat(5, 1fr);
    }
}

@media screen and (max-width: 1300px) {
    .about__skills ul {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: 1024px) {
    .about_me {
        width: 50%;
        margin: 2rem auto 4rem;
    }

    .about__paragraph {
        margin: 1rem 0 1.5rem;
    }

    .about__skills ul {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: 600px) {
    .about__me {
        width: 90%;
        margin: 0 auto 3rem;
        grid-template-columns: 1fr;
        gap: 0;
    }

    .about__cards {
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }

    .about__content {
        text-align: center;
    }

    .about__paragraph {
        margin-top: 2rem;
    }

    .about__skills ul {
        grid-template-columns: 1fr 1fr;
    }
}